import React from "react";
//import plugin from "../../public/assets/js/plugins";
//import common from "../../public/assets/js/common";



function Resume() {
    return (
<>
<div className="pb-3">
    <h1 className="title title--h1 title__separate">Resume</h1>
</div>


<div className="pb-0">
    <div className="row">
        <div className="col-12 col-lg-6">
            <h2 className="title title--h3"><img className="title-icon" src="assets/icons/icon-education.svg" alt="" /> Education</h2>
            <div className="timeline">
                
                <article className="timeline__item">
                    <h5 className="title title--h5 timeline__title">DCA In Hartron</h5>
                    <span className="timeline__period">2014 — 2015</span>
                    <p className="timeline__description">The DCA course contains the basic and the foundation subjects which is related to computer science and computer applications. The duration of the course is 1 year which consists of 2 semesters.</p>
                </article>

                
                <article className="timeline__item">
                    <h5 className="title title--h5 timeline__title">Sr. Secondary </h5>
                    <span className="timeline__period">2012-2013</span>
                    <p className="timeline__description">12th class passed in S.D. (Boys) Sr. Sec. School,Panipat in HBSE Board</p>
                </article>

                
                <article className="timeline__item">
                    <h5 className="title title--h5 timeline__title">Secondary </h5>
                    <span className="timeline__period">2010-2011</span>
                    <p className="timeline__description">10th class passed in L.S.B.T  Sr. Sec. School,Panipat in HBSE Board</p>
                </article>
            </div>
        </div>

        <div className="col-12 col-lg-6">
            <h2 className="title title--h3"><img className="title-icon" src="assets/icons/icon-experience.svg" alt="" /> Experience</h2>
            <div className="timeline">
                
                <article className="timeline__item">
                    <h5 className="title title--h5 timeline__title">Web Designer & Developer</h5>
                    <span className="timeline__period">2018 — Present</span>
                    <p className="timeline__description">working in Articulate Solution as a Web Developer & designer from June 2018</p>
                </article>

                
            </div>
        </div>
    </div>
</div>


<div className="box-inner box-inner--rounded">
    <div className="row">
        <div className="col-12 col-lg-6">
            <h2 className="title title--h3">Design Skills</h2>
            <div className="box box__second">
                
                <div className="progress">
                    <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%', zIndex: 2 }}>
                        <div className="progress-text"><span>Web Design (HTML / CSS)</span><span>100%</span></div>
                    </div>
                    <div className="progress-text"><span>Web Design (HTML / CSS)</span></div>
                </div>

                                
                <div className="progress">
                    <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '75%', zIndex: 2 }}>
                        <div className="progress-text"><span>Graphic Design</span><span>75%</span></div>
                    </div>
                    <div className="progress-text"><span>Graphic Design</span></div>
                </div>

                
                <div className="progress">
                    <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style={{ width: '90%', zIndex: 2 }}>
                        <div className="progress-text"><span>Photoshop</span><span>90%</span></div>
                    </div>
                    <div className="progress-text"><span>Photoshop</span></div>
                </div>

                
                <div className="progress">
                    <div className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{ width: '50%', zIndex: 2 }}>
                        <div className="progress-text"><span>Illustrator</span><span>50%</span></div>
                    </div>
                    <div className="progress-text"><span>Illustrator</span></div>
                </div>
            </div>
        </div>

        <div className="col-12 col-lg-6 mt-4 mt-lg-0">
            <h2 className="title title--h3">Coding Skills</h2>
            <div className="box box__second">
                
            <div className="progress">
                <div className="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: '60%', zIndex: 2 }}>
                    
                        <div className="progress-text"><span>React</span><span>60%</span></div>
                    </div>
                    <div className="progress-text"><span>React</span></div>
                </div>
            <div className="progress">
                <div className="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: '80%', zIndex: 2 }}>
                    
                        <div className="progress-text"><span>.Net Core</span><span>80%</span></div>
                    </div>
                    <div className="progress-text"><span>.Net Core</span></div>
                </div>

                <div className="progress">
                <div className="progress-bar" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100" style={{ width: '85%', zIndex: 2 }}>
                    
                        <div className="progress-text"><span>Asp.net MVC</span><span>85%</span></div>
                    </div>
                    <div className="progress-text"><span>Asp.net MVC</span></div>
                </div>

                
                <div className="progress">
                    <div className="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: '70%', zIndex: 2 }}>
                        <div className="progress-text"><span>Javascript</span><span>70%</span></div>
                    </div>
                    <div className="progress-text"><span>Javascript</span></div>
                </div>

                
            </div>
        </div>
    </div>
</div>
</>
)

}
export default Resume;