import React from 'react'

export default function Contact() {
  return (
    <>
    <div className="pb-2">
  <h1 className="title title--h1 title__separate">Contact</h1>
</div>
<iframe className="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13863.72566855659!2d76.9745484!3d29.692768!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390e70288d1aaddb%3A0x6dbbb584c6c75431!2sArticulate%20Solution%20-%20Website%20Design%2C%20Software%20Development%2C%20SEO%20Company!5e0!3m2!1sen!2sin!4v1681280424665!5m2!1sen!2sin" width="600" height="450"  style={{border:"0"}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>


<h2 className="title title--h3">Contact Form</h2>

<form id="contact-form" className="contact-form" data-toggle="validator">
  <div className="row">
      <div className="form-group col-12 col-md-6">
          <i className="font-icon icon-user"></i>
          <input type="text" className="input input__icon form-control" id="nameContact" name="nameContact" placeholder="Full name" required="required" autoComplete="on" />
          <div className="help-block with-errors"></div>
      </div>
      <div className="form-group col-12 col-md-6">
          <i className="font-icon icon-envelope"></i>
          <input type="email" className="input input__icon form-control" id="emailContact" name="emailContact" placeholder="Email address" required="required" autoComplete="on" />
          <div className="help-block with-errors"></div>
      </div>
      <div className="form-group col-12 col-md-12">
          <textarea className="textarea form-control" id="messageContact" name="messageContact" placeholder="Your Message" rows="4" required="required" ></textarea>
          <div className="help-block with-errors"></div>
      </div>
  </div>
  <div className="row">
      <div className="col-12 col-md-6 order-2 order-md-1 text-center text-md-left">
          <div id="validator-contact" className="hidden"></div>
      </div>
      <div className="col-12 col-md-6 order-1 order-md-2 text-right">
          <button type="submit" className="btn"><i className="font-icon icon-send"></i> Send Message</button>
      </div>
  </div>
</form>
    </>
  )
}

