import React, { useEffect } from "react";

import Preloder from "./Components/Preloder";
import MainComp from "./Components/MainComp";


// import FBchat from "./Components/FBchat";





function App() {

  
  return (
    <>
  
       {/* <FBchat /> */}

     <Preloder/>
     <MainComp />

     
    </>
  );
}
export default App;



