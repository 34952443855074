import Carousel from 'react-bootstrap/Carousel';

function CarouselData() {
  return (
    <Carousel fade>
      <Carousel.Item>
      <div className="swiper-slide review-item">
                <svg className="avatar avatar--80" viewBox="0 0 84 84">
                    <g className="avatar__hexagon">
                    <image href="/assets/img/anil.jpg" height="100%" width="100%" />
                    
                    </g>
                </svg>
                <h4 className="title title--h5">Anil Kumar</h4>
                <p className="review-item__caption">After sitting with various web developers around Panipat, Yogesh stood out from the rest.</p>
            </div>
        
        
      </Carousel.Item>
      <Carousel.Item>
      <div className="swiper-slide review-item">
                <svg className="avatar avatar--80" viewBox="0 0 84 84">
                    <a className="avatar__hexagon">
                        
                    <image href="/assets/img/vaibhav.jpg" height="100%" width="100%" />

                    </a>
                </svg>
                <h4 className="title title--h5">Vaibhav Mann</h4>
                <p className="review-item__caption">I really appreciate everything that Yogesh did for creating a new corporate website. He provided me his best support and I always get solutions.</p>
            </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="swiper-slide review-item">
                <svg className="avatar avatar--80" viewBox="0 0 84 84">
                    <g className="avatar__hexagon">
                        
                    <image href="/assets/img/rupinder.jpg" height="100%" width="100%" />
                    </g>
                </svg>
                <h4 className="title title--h5">Rupinder</h4>
                <p className="review-item__caption">Yogesh is very creative web developer in Panipat, India.</p>
            </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="swiper-slide review-item">
                <svg className="avatar avatar--80" viewBox="0 0 84 84">
                    <g className="avatar__hexagon">
                        
                    <image href="/assets/img/sarita.jpg" height="100%" width="100%" />
                    </g>
                </svg>
                <h4 className="title title--h5">Sarita Kashyap</h4>
                <p className="review-item__caption">
                    I just wanted to say thank you for developing my website. I am very pleased with it.
                    Thank you for your creative input and being patient with the multitude of small changes and I sought throughout the process.Thank You!!
                </p>
            </div>
      </Carousel.Item>

    </Carousel>
  );
}

export default CarouselData;