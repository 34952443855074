// import React from 'react';
// import ReactDOM from 'react-dom';
// import { Route, BrowserRouter as Router } from 'react-router-dom';
// import './index.css';
// import App from './App';
// import About from './Components/About';
// import Contact from './Components/Contact';

// const routing = (
//   <Router>

//       {/* <Route path="/" component={App} />   */}
//       <Route path="/about" component={About} />
//       <Route path="/contact" component={Contact} />

//   </Router>
// )
// ReactDOM.render(routing, document.getElementById('root'));

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/styles/style.css";
import "./assets/styles/mainstyle.css";
import "./assets/demo/style-demo.css";
import MetaDesc from "./Components/MetaDesc";
import "font-awesome/css/font-awesome.min.css";
// import "./assets/js/jquery-3.4.1.min.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <MetaDesc />
    <App />
  </>
);
reportWebVitals();
