
import 'swiper/swiper-bundle.min.css';
import {Link} from "react-router-dom";

import CarouselData from './CarouselData';
function About() {

      
    

    return (
        <>
        
<div className="row pb-0 pb-sm-2">
    <h1 className=" col-md-12 title title--h1 title__separate">About Me </h1>
    <div className="col-md-12">
        <p>I'm Web developer and web Designer from Panipat, Haryana, working in <b><Link to="http://articulatesolution.com/" target="_blank" rel="noopener noreferrer">Articulate Solution</Link></b> as a Web Developer from June 2018. I enjoy turning complex problems into simple, beautiful and intuitive Website designs.</p>
        <p>My job is to build your website so that it is functional and user-friendly but at the same time attractive. Moreover, I add personal touch to your product and make sure that is eye-catching and easy to use. My aim is to bring across your message and identity in the most creative way. I created web design for many famous brand companies.</p>
    </div>
    {/* <div className="col-md-6">
       <iframe className="introvideo" width="100%" src="https://www.youtube-nocookie.com/embed/lbsxMQLDahE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div> */}
</div>

<div className="box-inner pb-0">
    <h2 className="title title--h3">What I'm Doing</h2>
    <div className="row">
        <div className="col-12 col-lg-6">
            <div className="case-item box box__second">
                <img className="case-item__icon" src="assets/icons/icon-design.svg" alt="" />
                <div>
                    <h3 className="title title--h5">Web Design</h3>
                    <p className="case-item__caption">The most modern and high-quality design made at a professional level.</p>
                </div>
            </div>
        </div>

        <div className="col-12 col-lg-6">
            <div className="case-item box box__second">
                <img className="case-item__icon" src="assets/icons/icon-dev.svg" alt="" />
                <div>
                    <h3 className="title title--h5">Web Development</h3>
                    <p className="case-item__caption">High-quality development of sites at the professional level.</p>
                </div>
            </div>
        </div>

        <div className="col-12 col-lg-6">
            <div className="case-item box box__second">
                <img className="case-item__icon" src="assets/icons/icon-app.svg" alt="" />
                <div>
                    <h3 className="title title--h5">Mobile Apps</h3>
                    <p className="case-item__caption">Professional development of applications for iOS and Android.</p>
                </div>
            </div>
        </div>

        <div className="col-12 col-lg-6">
            <div className="case-item box box__second">
                <img className="case-item__icon" src="assets/icons/icon-photo.svg" alt="" />
                <div>
                    <h3 className="title title--h5">Photography</h3>
                    <p className="case-item__caption">I make high-quality photos of any category at a professional level.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="box-inner box-inner--white">
    <h2 className="title title--h3">Testimonials</h2>


<CarouselData/>

    
</div>


<script src="assets/js/common.js"></script>
        </>
    );
}
    
export default About;