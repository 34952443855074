import React from 'react'
import { Helmet } from 'react-helmet';
function MetaDesc() {
  return (
    <div>
        <Helmet>
        <meta charset="utf-8" />
        <title>Yogesh (Web Developer)</title>
        {/* Meta Data  */}
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="format-detection" content="address=no" />
        <meta name="author" content="Yogesh Kashyap" />
        <meta name="description" content="Best & Well experienced Web Developer in Panipat" />
        <meta name="keywords" content="Web Designer, Web Developer, Web Developer In Panipat, yogesh developer in Panipat, Website Designing in Panipat" />
        {/* Twitter data */}
        <meta name="twitter:card" content="Web Developer"/>
    <meta name="twitter:site" content="www.yogeshdeveloper.in"/>
    <meta name="twitter:title" content="Web Developer"/>
    <meta name="twitter:description" content="Best & Well experienced Web Developer in Panipat"/>
    <meta name="twitter:image" content="assets/img/yogi.png"/>
    {/*Open Graph data*/}
    <meta property="og:title" content="Web Developer" />
    <meta property="og:type" content="yogeshdeveloper.in" />
    <meta property="og:url" content="www.yogeshdeveloper.in" />
    <meta property="og:image" content="assets/img/yogi.png" />
    <meta property="og:description" content="Best & Well experienced Web Developer in Panipat" />
    <meta property="og:site_name" content="www.yogeshdeveloper.in" />
    {/*Favicons*/}
    <link rel="apple-touch-icon" sizes="144x144" href="assets/images/favicons/apple-touch-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="assets/images/favicons/apple-touch-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="assets/images/favicons/apple-touch-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="57x57" href="assets/images/favicons/apple-touch-icon-57x57.png" />
        <link rel="shortcut icon" href="assets/images/favicons/favicon.png" type="image/png" />

      </Helmet>
    </div>
  )
}

export default MetaDesc
