import React from "react";
import {Link} from "react-router-dom";
function Nav() {


    return (
<div  className="inner-menu js-menu">
      <ul id={"navlinks"} style={{display:"none"}}   className="nav">
        <li className="nav__item"><Link to="/About">About</Link></li>        
        <li className="nav__item"><Link to="/Resume">Resume</Link></li>
        <li className="nav__item"><Link to="/Contact">Contact</Link></li>
      </ul>
    </div>
    );
}
export default Nav;