import React from 'react';
import '../assets/img/yogi.png';
function SideComponent() {
  return (
    <aside className="col-12 col-md-12 col-xl-3">
                  <div className="sidebar box shadow pb-0 sticky-column">

                      <svg className="avatar avatar--180" viewBox="0 0 188 188">

                          <g className="avatar__box">

                              <image href='/assets/img/yogi.png' height="100%" width="100%" />
                             
                          </g>
                      </svg>
                      <div className="text-center">
                          <h3 className="title title--h3 sidebar__user-name">Yogesh</h3>
                          <div className="badge badge--light">Web Developer</div>

                        
                          <div className="social">
                              <a className="social__link" href="https://www.facebook.com/yogeshkashyap01" target="_blank"><i className="font-icon icon-facebook"></i></a>
                              <a className="social__link" href="https://www.twitter.com/YogeshKashyap01" target="_blank"><i className="font-icon icon-twitter"></i></a>
                              <a className="social__link" href="https://www.instagram.com/yogeshkashyap011/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                              <a className="social__link" href="https://wa.me/919729770858"><i className="fa fa-whatsapp" aria-hidden="true"></i></a>
                              <a className="social__link" href="https://www.upwork.com/freelancers/~010241147f03aac944" target="_blank"><img style={{height:"20px"}} className="upworklogo" src="/assets/img/upwork.png" /></a>
                          </div>
                      </div>

                      <div className="sidebar__info box-inner box-inner--rounded">
                          <ul style={{fontSize:" 13px"}} className="contacts-block">
                              <li className="contacts-block__item" data-toggle="tooltip" data-placement="top" title="Birthday">
                                  <i className="font-icon icon-calendar"></i>Sep 15, 1995
                              </li>
                              <li className="contacts-block__item" data-toggle="tooltip" data-placement="top" title="Address">
                                  <i className="font-icon icon-location"></i>Panipat, Haryana
                              </li>
                              <li className="contacts-block__item" data-toggle="tooltip" data-placement="top" title="E-mail">
                                  <a href="mailto:yogesh@articulatesolution.com"><i className="font-icon icon-envelope"></i>yogesh@articulatesolution.com</a>
                              </li>
                              <li className="contacts-block__item" data-toggle="tooltip" data-placement="top" title="Phone">
                                  <a href="tel:9729770858"><i className="font-icon icon-phone"></i>+91 9729-77-0858</a>
                              </li>

                          </ul>
                          <a className="btn" href="assets/img/yogesh~Resume.pdf" download><i className="font-icon icon-download"></i>Download CV</a>
                      </div>
                  </div>
              </aside>
  )
}

export default SideComponent
